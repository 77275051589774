/* src/components/Timeline.css */
.timeline {
  position: relative;
  padding: 2rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}
  
  .timeline-item {
    position: relative;
    width: 100%;
    padding: 1rem 0;
    display: flex;
    justify-content: center;
  }

  .timeline-line {
    position: absolute;
    width: 4px;
    background-color: #333;
    top: 0;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
}
  
  .timeline-date {
    position: absolute;
    font-size: var(--small-font-size);
    color: var(--title-color);
    left: 50%;
  }
  
  .timeline-content {
    left: 20px;
    top: 5px;
    margin-left: calc(50% + 4rem);
    padding: 10px;
    background-color: var(--container-color);
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 0.75rem;
    padding: 1rem 1.25rem;
  }
  
  .timeline-content h3,
  .timeline-content h4 {
    margin: 0;
  }
  
  .timeline-content p {
    margin: 5px 0 0;
  }


  /* Positioning classes for alternating layout 
.timeline-item.left .timeline-content {
  left: 10px;
  margin-left: 0;
  margin-right: auto;
  text-align: right;
}

.timeline-item.left .timeline-date {
  left: 10px;
  text-align: left;
}

.timeline-item.right .timeline-content {
  right: 10px;
  margin-left: auto;
  margin-right: 0;
  text-align: left;
}

.timeline-item.right .timeline-date {
  right: 10px;
  text-align: right;
}
  */



  .timeline-item.right .timeline-content {
    right: 1rem;
    margin-left: auto;
    margin-right: 60%;
    text-align: left;
  }

  .timeline-item.left .timeline-content {
    left: 1rem;
    margin-left: 55%;
    margin-right: auto;
    text-align: right;
  }

  .timeline-item.left .timeline-date {
    left: 25%;
    text-align: left;
  }

  .timeline-item.right .timeline-date {
    right: 25%;
    text-align: right;
  }


/*=============== BREAKPOINTS ===============*/
/* For large devices */
@media screen and (max-width: 992px) {
    .timeline-item.right .timeline-content {
      margin-right: 50%;
  }
  .timeline-item.left .timeline-content {
      margin-left: 50%;
  }
  .timeline-item.left .timeline-date {
      left: calc(20% + 1rem);
  }
  .timeline-item.right .timeline-date {
      right: calc(20% + 1rem);
  }
}

/* For medium devices */
@media screen and (max-width: 768px) {
    .timeline-item.right .timeline-content {
      margin-right: 40%;
  }
  .timeline-item.left .timeline-content {
      margin-left: 40%;
  }
  .timeline-item.left .timeline-date {
      left: calc(15% + 1rem);
  }
  .timeline-item.right .timeline-date {
      right: calc(15% + 1rem);
  }
}

@media screen and (max-width: 576px) {
  .timeline-item.right .timeline-content,
  .timeline-item.left .timeline-content {
      margin-left: auto;
      margin-right: auto;
      text-align: center;
      width: 95%;
      font-size: var(--small-font-size);
  }
  .timeline-item.left .timeline-date,
  .timeline-item.right .timeline-date {
      left: 50%;
      transform: translateX(-50%);
      text-align: center;
  }
  .timeline-date {
      font-size: var(--smaller-font-size);
      padding: 0.5rem;
  }
}

/* For small devices */
@media screen and (max-width: 350px) {
  .timeline-item.right .timeline-content,
  .timeline-item.left .timeline-content {
      width: 100%;
      font-size: var(--smaller-font-size);
  }
  .timeline-date {
    font-size: var(--smaller-font-size);
    text-align: center;
}
}